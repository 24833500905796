var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirm',{attrs:{"visible":_vm.showConfirm,"title":"Supprimer ce document","message":"Cette action ne peut être annulée. Voulez-vous continuer et supprimer ce document?"},on:{"close":function($event){return _vm.confirmDeleteCallback($event)}}}),[_c('v-row',{staticClass:"table-simple-filter"},[_c('c-col',[_c('div',{staticClass:"timeline-filters mt-1"},[_c('select-input',{attrs:{"items":_vm.documentCategories,"label":"Filtrer par type","name":"filter","clearable":""},on:{"change":function($event){return _vm.filterByCategory($event)}}})],1)])],1),_c('c-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documents,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.initLoading,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.defaultItemsPerPageWithAll, disablePagination: _vm.initLoading }},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('limited-char',{attrs:{"text":item.description,"limit":"40"}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('limited-char',{attrs:{"text":item.name,"limit":"40"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-row-actions"},[(item.id)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{directives:[{name:"auth-href",rawName:"v-auth-href",value:({downloadingText: 'chargement en cours', overrideInnerHtml: false, errorHandler: _vm.downloadErrorHandler}),expression:"{downloadingText: 'chargement en cours', overrideInnerHtml: false, errorHandler: downloadErrorHandler}"}],attrs:{"href":("" + _vm.downloadURLPrefix + (item.id)),"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc"},'v-icon',attrs,false),on),[_vm._v(" mdi-file-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.viewText))])]):_vm._e(),(item.id)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{directives:[{name:"auth-href",rawName:"v-auth-href",value:({downloadingText: 'téléchargement en cours', overrideInnerHtml: false, errorHandler: _vm.downloadErrorHandler}),expression:"{downloadingText: 'téléchargement en cours', overrideInnerHtml: false, errorHandler: downloadErrorHandler}"}],attrs:{"href":("" + _vm.downloadURLPrefix + (item.id))}},[_c('v-icon',_vm._g(_vm._b({staticClass:"action-doc"},'v-icon',attrs,false),on),[_vm._v(" mdi-file-download-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.downloadText))])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])])],1)]}},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); })),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }