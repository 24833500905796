import { DocumentInfoReq, DocumentInfoResponse } from '@/models/image-model'
import { Component, Vue } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { ErrorService } from '@/services/error.service'
import { DocumentService } from '@/services/patient-document-service'
import { documentDiversTypes } from '../Dossier/Constants'
import { Subscription } from 'rxjs'
import Commons from '@/components/shared/Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { ValidationObserver } from 'vee-validate'
import DocumentList from '@/components/shared/DocumentList/DocumentList.vue'

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver,
    DocumentList
  }
})
export default class DocumentDivers extends Vue {
  private readonly documentService = DocumentService.getInstance()
  private subcription!: Subscription
  public documentCategories = documentDiversTypes
  public dossierId!: string
  public apiURL = process.env.VUE_APP_API_URL

  public headersDocument: any[] = []

  public uploadDocuments: DocumentInfoReq[] = []
  public initLoading = false
  public documents: DocumentInfoResponse[] = []
  public documentsClone: DocumentInfoResponse[] = []
  public errorMessages: string[] = []
  public files: File[] = []
  private filterId!: number | undefined

  public mounted () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.headersDocument = [
      {
        text: 'Type',
        value: 'typeId',
        formatter: this.getCategoryNameById,
        sort: function (a, b) {
          return that.getCategoryNameById(a)!.localeCompare(that.getCategoryNameById(b)!)
        }
      },
      { text: 'Nom du document', value: 'description' },
      { text: 'Nom d\'origine', value: 'name' },
      { text: 'Date', value: 'dateCreated', formatter: Commons.TransformDateFormat },
      { text: '', value: 'actions', sortable: false, align: 'right' }
    ]
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      const selectedDossier = JSON.parse(cachedDossier!) as dossierPatientModel
      Commons.updateDossierName()
      this.dossierId = selectedDossier.guid
      this.getDocuments()
    } else {
      this.$router.push({ name: 'patient' })
    }
    this.subcription = this.documentService.uploadErrors$.subscribe(async errs => {
      if (errs.err) {
        const res = await ErrorService.handleError(errs.err)
        this.errorMessages.push(`${res.errors[0]} (${errs.image.name})`)
      }
    })
  }

  public get totalFileSize () {
    const size = this.uploadDocuments.map(req => req.file?.size ?? 0)
      .reduce((acc, value) => acc + value, 0)
    return (size / 1e+6).toFixed(2)
  }

  private getDocuments () {
    if (!this.dossierId) return
    this.initLoading = true
    this.documentService.getPatientDocumentDivers(this.dossierId)
      .then(documents => {
        this.documents = documents
        this.documentsClone = [...documents]
        this.filterByCategory(this.filterId)
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.initLoading = false
      })
  }

  public onChange (event: Event) {
    const fileList = (event.target as HTMLInputElement).files as FileList
    this.handleFileInput(fileList)
  }

  public filterByCategory (event?: number) {
    this.filterId = event
    if (!event) {
      this.documents = [...this.documentsClone]
    } else {
      const filterresults = this.documentsClone.filter(x => x.typeId === event)
      this.documents = filterresults
    }
  }

  public getCategoryNameById (categoryId: number) {
    return this.documentCategories.find(x => x.value === categoryId)?.text
  }

  private handleFileInput (fileList: FileList) {
    this.files = [...fileList]
    this.files.forEach(file => {
      this.uploadDocuments.push({ file: file })
    })
  }

  public remove (idx: number) {
    this.uploadDocuments.splice(idx, 1)
  }

  public dragover (event: Event) {
    event.preventDefault()
    if (!(event.currentTarget as HTMLInputElement).classList.contains('on-hover')) {
      (event.currentTarget as HTMLInputElement).classList.add('on-hover')
    }
  }

  public dragleave (event: Event) {
    (event.currentTarget as HTMLInputElement).classList.remove('on-hover')
  }

  public drop (event: DragEvent) {
    event.preventDefault()
    const dropFiles = (event.dataTransfer as DataTransfer).files
    this.handleFileInput(dropFiles)
    const target = event.currentTarget as HTMLInputElement
    target.classList.remove('on-hover')
  }

  public resetFiles () {
    this.uploadDocuments = []
    this.files = []
  }

  public async save () {
    this.errorMessages = []
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.initLoading = true
      await this.documentService.uploadDocuments(this.dossierId, this.uploadDocuments)
        .finally(() => {
          this.initLoading = false
          this.getDocuments()
        })
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public async doDelete (document: DocumentInfoResponse) {
    const results = await this.documentService.deletePatientDocument(document.id)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
    if (results === 200) {
      this.errorMessages = []
      this.getDocuments()
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public destroyed () {
    this.subcription.unsubscribe()
  }

  public handleDownloadError (e) {
    Commons.defaultVAuthHrefErrorHandler(this.errorMessages, e)
  }
}
