import { Component, Vue, Prop } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { ValidationObserver } from 'vee-validate'
import { DocumentAdminInfoResponse } from '@/models/document-admin-model'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

export type DocumentsFetcherFunction = (id: string) => Promise<any>

@Component({
  components: {
    Alert,
    Confirm,
    ValidationObserver
  }
})
export default class DocumentList extends Vue {
  @Prop({ default: [] })
  public headers!: any[]

  @Prop({ default: '' })
  public downloadURLPrefix!: string

  @Prop({ default: [] })
  public documentCategories!: {text: string; value: number}[]

  @Prop({ default: [] })
  public sortBy!: string[]

  @Prop({ default: [] })
  public sortDesc!: boolean[]

  @Prop({ default: [] })
  public documents!: any[]

  @Prop({ default: false })
  public initLoading!: boolean

  @Prop({ default: '' })
  public downloadText!: string

  @Prop({ default: '' })
  public viewText!: string

  public showConfirm = false
  public selectedDocument: any = {}
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public getCategoryNameById (categoryId: number) {
    return this.documentCategories.find(x => x.value === categoryId)?.text
  }

  public filterByCategory (event?: number) {
    this.$emit('filterDocument', event)
  }

  public displayDeleteConfirmDialog (document: DocumentAdminInfoResponse) {
    this.showConfirm = true
    this.selectedDocument = document
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.$emit('deleteDocument', this.selectedDocument)
      this.selectedDocument = {}
    }
  }

  public get downloadErrorHandler () {
    return (e) => {
      this.$emit('downloadError', e)
    }
  }
}
